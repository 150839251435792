var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c("a-col", { attrs: { span: 12 } }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("lbl_currency_list")))])
          ]),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _vm.hasPrivilegeCreate
                ? _c(
                    "a-button",
                    {
                      attrs: { icon: "plus", type: "primary" },
                      on: { click: _vm.showForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.listCurrency.data,
                    loading: _vm.loader.table,
                    pagination: false,
                    scroll: { y: 150 }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "currencyCode",
                      attrs: {
                        "data-index": "currencyCode",
                        "row-key": "description"
                      }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_currency_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "description",
                      attrs: {
                        "data-index": "description",
                        "row-key": "description"
                      }
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_description")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.$t("lbl_create_new_currency") },
          model: {
            value: _vm.mForm,
            callback: function($$v) {
              _vm.mForm = $$v
            },
            expression: "mForm"
          }
        },
        [
          _c(
            "a-form",
            { attrs: { align: "left", form: _vm.form } },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.code.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.code.decorator,
                        expression: "formRules.code.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.code.name,
                      placeholder: _vm.formRules.code.placeholder
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.description.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.description.decorator,
                        expression: "formRules.description.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.description.name,
                      placeholder: _vm.formRules.description.placeholder
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    { attrs: { type: "danger" }, on: { click: _vm.closeForm } },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loader.form },
                      on: { click: _vm.submitForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }