
























































































import Vue from "vue";
import { settingsServices } from "@/services/settings.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseListOfMasterCurrency } from "@/models/interface/settings.interface";
import { mapGetters } from "vuex";
export default Vue.extend({
  name: "TableCurrencyList",
  data() {
    return {
      listCurrency: {} as ResponseListOfMasterCurrency,
      loader: {
        table: false,
        form: false
      },
      mForm: false,
      form: this.$form.createForm(this, { name: "currency" }),
      formRules: {
        code: {
          label: this.$t("lbl_currency_code"),
          name: "Currency Code",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        description: {
          label: this.$t("lbl_description"),
          name: "Description",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        fromcurrency: {
          label: this.$t("lbl_from_currency"),
          name: "From Currency",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "fromcurrency",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        tocurrency: {
          label: this.$t("lbl_to_currency"),
          name: "To Currency",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "tocurrency",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        rate: {
          label: this.$t("lbl_rates"),
          name: "Rate",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "rate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "accounting-currency" && x.privilege.update && x.privilege.create);
    },
  },
  mounted(): void {
    this.getListOfMasterCurrency();
  },
  methods: {
    async getListOfMasterCurrency(): Promise<void> {
      try {
        const params = {
          limit: 1000,
          page: 0,
        } as RequestQueryParamsModel;
        this.loader.table = true;
        const res = await settingsServices.listOfMasterCurrency(params, "");
        res.data.forEach((el, i: number) => el.key = i);
        this.listCurrency = res;
      } catch (error) {
        console.error(error);
      } finally {
        this.loader.table = false;
      }
    },
    showForm(): void {
      this.mForm = true;
    },
    closeForm(): void {
      this.mForm = false;
    },
    async createCurrency(data: {code: string, description: string}): Promise<void> {
      try {
        const req = {
          currencyCode: data.code,
          description: data.description,
        };
        this.loader.form = true;
        await settingsServices.createMasterCurrency(req);
        this.getListOfMasterCurrency();
        this.form.resetFields();
        this.$message.success(this.$t("notif_create_success").toString());
        this.closeForm();
        this.$emit("onCurrCreated", true);
      } catch (error) {
        this.$notification.error({
          message: this.$t("lbl_error_title").toString(),
          description: this.$t("lbl_error_general_description").toString(),
        });
      } finally {
        this.loader.form = false;
      }
    },
    submitForm(): void {
      this.form.validateFields((err,values) => {
        if (!err) {
          this.createCurrency(values);
        } else {
          this.$notification["error"]({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
  }
});
